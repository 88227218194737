import {
	type AppID,
	type NubeSDKRuntime,
	type NubeSDKRuntimeEvent,
	type NubeSDKRuntimeState,
	type NubeSDKState,
	NubeSdkInternalEventData,
	WORKER_PRIVATE_STATE_PATHS,
	type WorkerInternalMessage,
	type WorkerMessage,
	isInternalEvent,
} from "@tiendanube/nube-sdk-internal-types";
import { removeObjectKeys } from "./object-keys-utils";
import {
	createWorkerInternalMessageHandler,
	workerMessageHandler,
} from "./worker-handler";

export function isInternalMessage(
	message: WorkerMessage | WorkerInternalMessage,
): message is WorkerInternalMessage {
	return isInternalEvent(message.type);
}

export function addWorkerEventListener(
	id: AppID,
	worker: Worker,
	runtime: NubeSDKRuntime,
) {
	const internalMessageHandler = createWorkerInternalMessageHandler(
		id,
		worker,
		{
			localStorage: localStorage,
			sessionStorage: sessionStorage,
		},
	);

	worker.addEventListener("message", (m: MessageEvent<string>) => {
		try {
			const message = JSON.parse(m.data) as
				| WorkerMessage
				| WorkerInternalMessage;

			try {
				if (String(message.app) === String(id)) {
					if (isInternalMessage(message)) {
						internalMessageHandler(message);
					} else {
						workerMessageHandler(message, runtime);
					}
					return;
				}
			} catch (err) {
				console.error(`Error handling worker message: ${err}`);
				return;
			}
			console.error(`App ${id} sent message with invalid id ${message.app}`);
		} catch (err) {
			console.error("Unable to parse worker app message");
		}
	});

	runtime.on(
		"*",
		(state: NubeSDKRuntimeState, event: NubeSDKRuntimeEvent, appid, target) => {
			if (!target || target === appid) {
				const { apps, ...runtimeState } = state;

				const stateToSend: NubeSDKState = removeObjectKeys(
					runtimeState,
					WORKER_PRIVATE_STATE_PATHS,
				);

				worker.postMessage(
					JSON.stringify({
						type: event,
						state: stateToSend,
					}),
				);
			}
		},
	);
}
