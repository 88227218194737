import type {
	AppID,
	CartValidation,
	NubeComponent,
	NubeSDKRuntime,
	NubeSDKSendableEvent,
	NubeSdkInternalEvent,
	NubeSdkInternalEventData,
	NubeStorageGetResponseEventData,
	NubeStorageId,
	NubeStorageQueryEventData,
	NubeStorageRemoveResponseEventData,
	NubeStorageSetEventData,
	NubeStorageSetResponseEventData,
	UISlot,
	UISlots,
	WorkerInternalMessage,
	WorkerMessage,
	WorkerMessageHandlerFunc,
} from "@tiendanube/nube-sdk-internal-types";

export function parseCartValidation(value: unknown): CartValidation {
	const val = value as CartValidation | undefined;

	if (!val) return { status: "pending" };

	switch (val.status) {
		case "fail":
			if (typeof val.reason === "string")
				return { status: "fail", reason: val.reason };
			break;

		case "success":
			return { status: "success" };
	}

	return { status: "pending" };
}

export const handleCartValidation: WorkerMessageHandlerFunc = ({
	app,
	changes,
}) => ({
	apps: {
		[app]: {
			cart: {
				validation: parseCartValidation(changes?.cart?.validation),
			},
		},
	},
});

export const handleSlotSet: WorkerMessageHandlerFunc = (
	{ app: appid, changes },
	state,
) => {
	const slots = changes?.ui?.slots;
	if (!slots) return {};

	const appUISlotsMerged: UISlots = {
		...(state.apps?.[appid]?.ui?.slots || {}),
	};
	const appUIValues = state.apps?.[appid]?.ui?.values || {};

	// Merge specific app ui slots into existing ones

	// We treat slots that are present in the slots array BUT don't have a component assigned as
	// an implicit removal of all components from the slot.
	for (const [slotKey, component] of Object.entries(slots)) {
		const slot = slotKey as UISlot;

		if (component) {
			appUISlotsMerged[slot] = component;
		} else {
			delete appUISlotsMerged[slot];
		}
	}

	// Merge all app ui slots together into the global ui slots

	// Start by collecting all used slots
	const usedSlots: Partial<Record<UISlot, NubeComponent[]>> = {};

	for (const [appKey, app] of Object.entries(state.apps)) {
		const appUISlots = appKey === appid ? appUISlotsMerged : app.ui?.slots;

		if (!appUISlots) continue;

		for (const [slotKey, component] of Object.entries(appUISlots)) {
			const slot = slotKey as UISlot;

			if (!usedSlots[slot]) {
				usedSlots[slot] = [];
			}

			usedSlots[slot].push(component);
		}
	}

	// Now merge the slots together, selecting the first component of each slot if there is more than one component,
	// in the future we can add a priority system to select the component to render, or generate a new container
	// that put ones after another
	const uiSlotsMerged: UISlots = {};
	const uiValues = state.ui.values;

	for (const [slotKey, components] of Object.entries(usedSlots)) {
		const slot = slotKey as UISlot;

		if (components.length > 1) {
			console.warn(
				`Slot ${slot} has more than one UI component assigned, only the first one will be used`,
			);
		}

		uiSlotsMerged[slot] = components[0];
	}

	return {
		apps: {
			[appid]: {
				ui: {
					slots: appUISlotsMerged,
					values: appUIValues,
				},
			},
		},
		ui: {
			slots: uiSlotsMerged,
			values: uiValues,
		},
	};
};

export const handleConfigSet: WorkerMessageHandlerFunc = ({
	app,
	changes,
}) => ({
	apps: {
		[app]: {
			config: {
				has_cart_validation: changes?.config?.has_cart_validation || false,
				disable_shipping_more_options:
					changes?.config?.disable_shipping_more_options || false,
			},
		},
	},
});

export const handleWebWorkerError: WorkerMessageHandlerFunc = (
	{ app, error },
	state,
) => ({
	apps: {
		[app]: {
			errors: [...(state.apps?.[app]?.errors || []), error],
		},
	},
});

const handlers = new Map<
	NubeSDKSendableEvent | "WebWorkerError",
	WorkerMessageHandlerFunc
>([
	["config:set", handleConfigSet],
	["cart:validate", handleCartValidation],
	["ui:slot:set", handleSlotSet],
	["WebWorkerError", handleWebWorkerError],
]);

export function createWorkerMessageHandler(
	handlers: Map<
		NubeSDKSendableEvent | "WebWorkerError",
		WorkerMessageHandlerFunc
	>,
) {
	return (message: WorkerMessage, runtime: NubeSDKRuntime) => {
		const { app, type, changes } = message;
		const handler = handlers.get(type);

		if (handler) {
			runtime.send(app, type, (state) => handler(message, state));
			return;
		}

		if (changes) {
			runtime.send(app, type, () => changes);
			return;
		}

		runtime.send(app, type);
	};
}

export const workerMessageHandler = createWorkerMessageHandler(handlers);

export type WorkerInternalMessageOptions = {
	localStorage: Storage;
	sessionStorage: Storage;
};

export function buildAppStorageKey(appId: AppID, key: string) {
	return `app-${appId}-${key}`;
}

export function encodeAppStorageValue(
	value: string | null,
	ttl?: number,
	now: number = Date.now(),
) {
	const data = {
		value,
		...(ttl !== undefined ? { ttl: now + ttl * 1000 } : {}),
	};
	return JSON.stringify(data);
}

export function decodeAppStorageValue(
	storageValue: string | null,
	now = Date.now(),
): string | null {
	if (!storageValue) return null;
	try {
		const data = JSON.parse(storageValue);

		if (!data) {
			return null;
		}

		if (typeof data !== "object") {
			console.error(
				"Invalid value stored in storage, maybe it was modified from outside the SDK",
				storageValue,
			);
			return null;
		}

		if (data?.ttl) {
			// Has expiration date, validate
			if (typeof data.ttl !== "number") {
				console.error(
					"Invalid value stored in storage, maybe it was modified from outside the SDK",
					storageValue,
				);
				return null;
			}
			const isExpired = !!data.ttl && data.ttl < now;
			if (isExpired) return null;
		}

		if (data.value && typeof data.value !== "string") {
			console.error(
				"Invalid value stored in storage, maybe it was modified from outside the SDK",
				storageValue,
			);
			return null;
		}

		if (data.value === undefined) return null;

		return data.value;
	} catch (err) {
		console.error(
			"Invalid value stored in storage, maybe it was modified from outside the SDK",
			storageValue,
			err,
		);
		return null;
	}
}

export function createWorkerInternalMessageHandler(
	appId: AppID,
	worker: Worker,
	options: WorkerInternalMessageOptions,
): (message: WorkerInternalMessage) => void {
	function getStorage(storageId: NubeStorageId) {
		switch (storageId) {
			case "session-storage":
				return options.sessionStorage;
			case "local-storage":
				return options.localStorage;
		}
	}

	function postMessage(
		eventId: NubeSdkInternalEvent,
		data: NubeSdkInternalEventData,
	) {
		worker.postMessage(
			JSON.stringify({
				type: eventId,
				data: data,
			}),
		);
	}

	function isEventStorageQueryEvent(event: WorkerInternalMessage): boolean {
		return (
			event.type === "internal:storage:get" ||
			event.type === "internal:storage:set" ||
			event.type === "internal:storage:remove"
		);
	}

	return (message) => {
		if (isEventStorageQueryEvent(message)) {
			const data = message.data as NubeStorageQueryEventData;
			const storage = getStorage(data.storageId);
			const storageKey = buildAppStorageKey(appId, data.key);

			switch (message.type) {
				case "internal:storage:get": {
					const storageValue = storage.getItem(storageKey);
					const value = decodeAppStorageValue(storageValue);
					const response: NubeStorageGetResponseEventData = {
						storageId: data.storageId,
						key: data.key,
						requestId: data.requestId,
						value,
					};
					postMessage("internal:storage:get:response", response);
					break;
				}

				case "internal:storage:set": {
					const setEventData = data as NubeStorageSetEventData;
					const finalValue = encodeAppStorageValue(
						setEventData.value,
						setEventData.ttl,
					);
					try {
						storage.setItem(storageKey, finalValue);
					} catch (err) {
						// Setting an item in storage can fail if the storage is full, we log the error but we don't propagate it to the worker.. maybe
						// this should be propagated?
						console.error(err);
					}
					const response: NubeStorageSetResponseEventData = {
						storageId: data.storageId,
						requestId: data.requestId,
					};
					postMessage("internal:storage:set:response", response);
					break;
				}

				case "internal:storage:remove": {
					storage.removeItem(storageKey);
					const response: NubeStorageRemoveResponseEventData = {
						storageId: data.storageId,
						requestId: data.requestId,
					};
					postMessage("internal:storage:remove:response", response);
					break;
				}
			}
		}
	};
}

export function createWorker(...parts: string[]): Worker {
	const blob = new Blob(parts, { type: "application/javascript" });
	const worker = new Worker(URL.createObjectURL(blob));
	return worker;
}
